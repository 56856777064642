import { message } from "antd";

export function numberWithSpaces(x) {
  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  return parts.join(".");
}

export function openLink(url) {
  var otherWindow = window.open();
  if (otherWindow) {
    otherWindow.opener = null;
    otherWindow.location = url;
  }
}

export const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
export const beforeUpload = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
};

export const queryMessage = (method, status, msg = "") => {
  if (method === "post") {
    message.success("Создан успешно!");
  } else if (method === "put") {
    message.success("Изменён успешно!");
  } else if (method === "delete") {
    message.success("Удалён успешно!");
  } else if (status === "success") {
    message.success(msg);
  }
};

export const text = {
  isEmpty: "Поле не заполнено",
};

export const putZero = (num) => {
  if (num < 10) return `0${num}`;
  else return `${num}`;
};

export const onSetFormat = (date) => {
  return !date
    ? ""
    : date.getFullYear() +
        "-" +
        putZero(date.getMonth() + 1) +
        "-" +
        putZero(date.getDate());
};

export const getMonthShortName = (key) => {
  const month = {
    "01": "янв",
    "02": "фев",
    "03": "мар",
    "04": "апр",
    "05": "май",
    "06": "июн",
    "07": "июл",
    "08": "авг",
    "09": "сен",
    10: "окт",
    11: "ноя",
    12: "дек",
  };
  return month?.[key];
};
