import { createSlice } from "@reduxjs/toolkit";
import { saveToken } from "../../utils/Token";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosBaseRequest, axiosBaseUrl } from "../../utils/axiosBaseUrl";
import { queryMessage } from "../../utils/globalFunction";

// AUTH ==========
export const loginUser = createAsyncThunk("loginUser", async (body) => {
  try {
    const data = await axiosBaseUrl.post("/login", body);
    if (data.status !== 401) {
      saveToken(data.data.jwt_token, data.data.refresh_token);
      window.location.href = "/admin";
    }
    return data.data;
  } catch (error) {
    console.log("error", error);
  }
});

// MAIN ==========
export const getMainAll = createAsyncThunk(
  "getMainAll",
  async ({ limit = 50, offset = 0 }) => {
    try {
      const { data } = await axiosBaseUrl.get(
        `/main/all?limit=${limit}&offset=${offset}`
      );
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

export const getMain = createAsyncThunk("getMain", async ({ id }) => {
  try {
    const { data } = await axiosBaseUrl.get(`/main/all?content_id=${id}`);
    return data;
  } catch (error) {
    console.log("error", error);
  }
});

export const putMain = createAsyncThunk(
  "putMain",
  async (body, { dispatch }) => {
    try {
      const { data } = await axiosBaseRequest.put(`/main`, body);
      queryMessage("put");
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

export const putMainFile = createAsyncThunk(
  "putMainFile",
  async (body, { dispatch }) => {
    try {
      const { data } = await axiosBaseRequest.put(`/main/file`, body, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// PARTNERS ==========
export const getPartners = createAsyncThunk(
  "getPartners",
  async ({ limit, offset }) => {
    try {
      const { data } = await axiosBaseUrl.get(
        `/partners?limit=${limit}&offset=${offset}`
      );
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

export const createPartners = createAsyncThunk(
  "createPartners",
  async (body, { dispatch }) => {
    try {
      const { data } = await axiosBaseRequest.post(`/partners`, body, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      dispatch(getPartners({ limit: 50, offset: 0 }));
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

export const deletePartners = createAsyncThunk(
  "deletePartners",
  async (body) => {
    try {
      const { data } = await axiosBaseRequest.delete(`/partners?id=${body.id}`);
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// PARTICIPANTS ==========
export const getParticipants = createAsyncThunk(
  "getParticipants",
  async ({ limit, offset, id }) => {
    try {
      const { data } = await axiosBaseUrl.get(
        `/participants?id=${id}&limit=${limit}&offset=${offset}`
      );
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

export const createParticipants = createAsyncThunk(
  "createParticipants",
  async ({ body, id }, { dispatch }) => {
    try {
      const { data } = await axiosBaseRequest.post(`/participants`, body, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      dispatch(getParticipants({ limit: 50, offset: 0, id }));
      window.location.href = "/admin/participants";
      queryMessage("post");
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

export const deleteParticipants = createAsyncThunk(
  "deleteParticipants",
  async (body) => {
    try {
      const { data } = await axiosBaseRequest.delete(
        `/participants?id=${body.id}`
      );
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// STAND REQUEST ==========
export const getStandRequest = createAsyncThunk(
  "getStandRequest",
  async ({ limit, offset }) => {
    try {
      const { data } = await axiosBaseRequest.get(
        `/stand_request?limit=${limit}&offset=${offset}`
      );
      return { data, limit, offset };
    } catch (error) {
      console.log("error", error);
    }
  }
);

export const createStandRequest = createAsyncThunk(
  "createStandRequest",
  async (body) => {
    try {
      const { data } = await axiosBaseRequest.post(`/stand_request`, body);
      queryMessage("", "success", "Отправлено");
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// MEDIA ==========
export const getMedia = createAsyncThunk(
  "getMedia",
  async ({ limit, offset }) => {
    try {
      const { data } = await axiosBaseUrl.get(
        `/media?limit=${limit}&offset=${offset}`
      );
      return { data, limit, offset };
    } catch (error) {
      console.log("error", error);
    }
  }
);

export const createMedia = createAsyncThunk("createMedia", async (body) => {
  try {
    const { data } = await axiosBaseRequest.post(`/media`, body, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    queryMessage("post");
    return data;
  } catch (error) {
    console.log("error", error);
  }
});

export const putMedia = createAsyncThunk("putMedia", async (body) => {
  try {
    const { data } = await axiosBaseRequest.put(`/media`, body);
    queryMessage("put");
    return data;
  } catch (error) {
    console.log("error", error);
  }
});

export const deleteMedia = createAsyncThunk("deleteMedia", async (body) => {
  try {
    const { data } = await axiosBaseRequest.delete(
      `/media?media_id=${body.id}`
    );
    queryMessage("delete");
    return data;
  } catch (error) {
    console.log("error", error);
  }
});

export const createMediaImage = createAsyncThunk(
  "createMediaImage",
  async (body) => {
    try {
      const { data } = await axiosBaseRequest.post(`/media/image`, body, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

export const deleteMediaImage = createAsyncThunk(
  "deleteMediaImage",
  async (body) => {
    try {
      const { data } = await axiosBaseRequest.delete(
        `/media/image?img_id=${body.id}`
      );
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// VIDEOGALLERY ==========
export const getVideoGallery = createAsyncThunk(
  "getVideoGallery",
  async ({ limit, offset }) => {
    try {
      const { data } = await axiosBaseUrl.get(
        `/videogallery?limit=${limit}&offset=${offset}`
      );
      return { data, limit, offset };
    } catch (error) {
      console.log("error", error);
    }
  }
);

export const createVideoGallery = createAsyncThunk(
  "createVideoGallery",
  async (body, { dispatch }) => {
    try {
      const { data } = await axiosBaseRequest.post(`/videogallery`, body, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      dispatch(getVideoGallery({ limit: 16, offset: 0 }));
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

export const deleteVideoGallery = createAsyncThunk(
  "deleteVideoGallery",
  async (body) => {
    try {
      const { data } = await axiosBaseRequest.delete(
        `/videogallery?id=${body.id}`
      );
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// PHOTOGALLERY ==========
export const getPhotoGallery = createAsyncThunk(
  "getPhotoGallery",
  async ({ limit, offset }) => {
    try {
      const { data } = await axiosBaseUrl.get(
        `/photogallery?limit=${limit}&offset=${offset}`
      );
      return { data, limit, offset };
    } catch (error) {
      console.log("error", error);
    }
  }
);

export const createPhotoGallery = createAsyncThunk(
  "createPhotoGallery",
  async (body, { dispatch }) => {
    try {
      const { data } = await axiosBaseRequest.post(`/photogallery`, body, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      dispatch(getPhotoGallery({ limit: 20, offset: 0 }));
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

export const deletePhotoGallery = createAsyncThunk(
  "deletePhotoGallery",
  async (body) => {
    try {
      const { data } = await axiosBaseRequest.delete(
        `/photogallery?id=${body.id}`
      );
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// EXHIBITOIN ==========
export const getExhibition = createAsyncThunk(
  "getExhibition",
  async ({ limit, offset }) => {
    try {
      const { data } = await axiosBaseUrl.get(
        `/exhibition?limit=${limit}&offset=${offset}`
      );
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

export const createExhibition = createAsyncThunk(
  "createExhibition",
  async (body, { dispatch }) => {
    try {
      const { data } = await axiosBaseRequest.post(`/exhibition`, body);
      queryMessage("post");
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

export const putExhibition = createAsyncThunk("putExhibition", async (body) => {
  try {
    const { data } = await axiosBaseRequest.put(`/exhibition`, body);
    queryMessage("put");
    return { data, body };
  } catch (error) {
    console.log("error", error);
  }
});

export const deleteExhibition = createAsyncThunk(
  "deleteExhibition",
  async (body) => {
    try {
      const { data } = await axiosBaseRequest.delete(
        `/exhibition?exhibition_id=${body.id}`
      );
      queryMessage("delete");
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

export const getExhibitionImage = createAsyncThunk(
  "getExhibitionImage",
  async (body, { dispatch }) => {
    try {
      const { data } = await axiosBaseRequest.get(
        `/exhibition/images?id=${body.id}`
      );
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

export const getExhibitionVideo = createAsyncThunk(
  "getExhibitionVideo",
  async (body, { dispatch }) => {
    try {
      const { data } = await axiosBaseRequest.get(
        `/exhibition/videos?id=${body.id}`
      );
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

export const createExhibitionImage = createAsyncThunk(
  "createExhibitionImage",
  async ({ body, id }, { dispatch }) => {
    try {
      const { data } = await axiosBaseRequest.post(`/exhibition/image`, body, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      queryMessage("post");
      dispatch(getExhibitionImage({ id }));
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

export const createExhibitionVideo = createAsyncThunk(
  "createExhibitionVideo",
  async ({ body, id }, { dispatch }) => {
    try {
      const { data } = await axiosBaseRequest.post(`/exhibition/video`, body, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      queryMessage("post");
      dispatch(getExhibitionVideo({ id }));
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

export const deleteExhibitionFile = createAsyncThunk(
  "deleteExhibitionFile",
  async ({ id, isImage = true, exhibition_id }, { dispatch }) => {
    try {
      const { data } = await axiosBaseRequest.delete(
        `/exhibition/file?file_id=${id}`
      );
      if (isImage) {
        dispatch(getExhibitionImage({ id: exhibition_id }));
      } else {
        dispatch(getExhibitionVideo({ id: exhibition_id }));
      }
      queryMessage("delete");
      return { data };
    } catch (error) {
      console.log("error", error);
    }
  }
);

// NEWS ==========
export const getNews = createAsyncThunk(
  "getNews",
  async ({ limit, offset }) => {
    try {
      const { data } = await axiosBaseUrl.get(
        `/news?limit=${limit}&offset=${offset}`
      );
      return { data, limit, offset };
    } catch (error) {
      console.log("error", error);
    }
  }
);

export const createNews = createAsyncThunk("createNews", async (body) => {
  try {
    const { data } = await axiosBaseRequest.post(`/news`, body);
    queryMessage("post");
    return data;
  } catch (error) {
    console.log("error", error);
  }
});

export const putNews = createAsyncThunk("putNews", async (body) => {
  try {
    const { data } = await axiosBaseRequest.put(`/news`, body);
    queryMessage("put");
    return { data, body };
  } catch (error) {
    console.log("error", error);
  }
});

export const deleteNews = createAsyncThunk("deleteNews", async (body) => {
  try {
    const { data } = await axiosBaseRequest.delete(`/news?id=${body.id}`);
    queryMessage("delete");
    return data;
  } catch (error) {
    console.log("error", error);
  }
});

const initialState = {
  // Query states
  loading: false,
  error: false,
  currentExhibition: {},

  limitOffset: {
    news: {
      limit: 9,
      offset: 0,
    },
    exhibition: {
      limit: 999,
      offset: 0,
    },
    photoGallery: {
      limit: 20,
      offset: 0,
    },
    videoGallery: {
      limit: 16,
      offset: 0,
    },
    media: {
      limit: 8,
      offset: 0,
    },
    standRequest: {
      limit: 9,
      offset: 0,
    },
    participants: {
      limit: 100,
      offset: 0,
    },
    partners: {
      limit: 100,
      offset: 0,
    },
  },

  previewImage: "",
  previewOpen: false,
  previewVideo: { open: false, url: "" },

  // get datas
  partners: [],
  participants: [],
  standRequest: [],
  media: [],
  videoGallery: [],
  photoGallery: [],
  exhibition: [],
  exhibitionImage: [],
  exhibitionVideo: [],
  news: [],
  mainAll: [],

  formState: {},
};

const globalState = createSlice({
  name: "globalState",
  initialState,
  reducers: {
    // Left menu func.
    setLimitOffset: (state, action) => {
      state.limitOffset = action.payload;
    },
    setFormState: (state, action) => {
      state.formState = action.payload;
    },

    setPreviewOpen: (state, action) => {
      state.previewOpen = action.payload;
    },
    setPreviewImage: (state, action) => {
      state.previewImage = action.payload;
    },
    setPreviewVideo: (state, action) => {
      state.previewVideo = action.payload;
    },
  },
  extraReducers: (builder) => {
    // AUTH ==========
    builder.addCase(loginUser.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(loginUser.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(loginUser.rejected, (state, action) => {
      state.loading = false;
    });

    // MAIN ==========
    builder.addCase(getMainAll.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getMainAll.fulfilled, (state, action) => {
      state.loading = false;
      state.mainAll = action.payload;
    });
    builder.addCase(getMainAll.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(getMain.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getMain.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getMain.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(putMain.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(putMain.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(putMain.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(putMainFile.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(putMainFile.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(putMainFile.rejected, (state, action) => {
      state.loading = false;
    });

    // PARTNERS ==========
    builder.addCase(getPartners.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getPartners.fulfilled, (state, action) => {
      state.loading = false;
      state.partners = action.payload;
    });
    builder.addCase(getPartners.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(createPartners.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(createPartners.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(createPartners.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(deletePartners.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(deletePartners.fulfilled, (state, action) => {
      state.loading = false;
      state.partners = state.partners.filter(
        (el) => el.id !== action.payload?.id
      );
    });
    builder.addCase(deletePartners.rejected, (state, action) => {
      state.loading = false;
    });

    // PARTICIPANTS ==========
    builder.addCase(getParticipants.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getParticipants.fulfilled, (state, action) => {
      state.loading = false;
      state.participants = action.payload;
    });
    builder.addCase(getParticipants.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(createParticipants.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(createParticipants.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(createParticipants.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(deleteParticipants.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(deleteParticipants.fulfilled, (state, action) => {
      state.loading = false;
      state.participants = state.participants.filter(
        (el) => el.id !== action.payload?.id
      );
    });
    builder.addCase(deleteParticipants.rejected, (state, action) => {
      state.loading = false;
    });

    // STAND REQUEST ==========
    builder.addCase(getStandRequest.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getStandRequest.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload?.offset > 0) {
        state.standRequest = [...state.standRequest, ...action.payload?.data];
      } else {
        state.standRequest = action.payload?.data;
      }
    });
    builder.addCase(getStandRequest.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(createStandRequest.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(createStandRequest.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(createStandRequest.rejected, (state, action) => {
      state.loading = false;
    });

    // MEDIA ==========
    builder.addCase(getMedia.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getMedia.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload?.offset > 0) {
        state.media = [...state.media, ...action.payload?.data];
      } else {
        state.media = action.payload?.data;
      }
    });
    builder.addCase(getMedia.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(createMedia.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(createMedia.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(createMedia.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(putMedia.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(putMedia.fulfilled, (state, action) => {
      state.loading = false;
      state.news = action.payload;
    });
    builder.addCase(putMedia.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(deleteMedia.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(deleteMedia.fulfilled, (state, action) => {
      state.loading = false;
      state.media = state.media?.filter((el) => el.id !== action.payload?.id);
    });
    builder.addCase(deleteMedia.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(createMediaImage.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(createMediaImage.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(createMediaImage.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(deleteMediaImage.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(deleteMediaImage.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(deleteMediaImage.rejected, (state, action) => {
      state.loading = false;
    });

    // VIDEOGALLERY ==========
    builder.addCase(getVideoGallery.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getVideoGallery.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload?.offset > 0) {
        state.videoGallery = [...state.videoGallery, ...action.payload?.data];
      } else {
        state.videoGallery = action.payload?.data;
      }
    });
    builder.addCase(getVideoGallery.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(createVideoGallery.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(createVideoGallery.fulfilled, (state, action) => {
      state.loading = false;
      state.limitOffset = {
        ...state.limitOffset,
        photoGallery: { limit: 16, offset: 0 },
      };
    });
    builder.addCase(createVideoGallery.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(deleteVideoGallery.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(deleteVideoGallery.fulfilled, (state, action) => {
      state.loading = false;
      state.videoGallery = state.videoGallery.filter(
        (el) => el.id !== action.payload?.id
      );
    });
    builder.addCase(deleteVideoGallery.rejected, (state, action) => {
      state.loading = false;
    });

    // PHOTOGALLERY ==========
    builder.addCase(getPhotoGallery.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getPhotoGallery.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload?.offset > 0) {
        state.photoGallery = [...state.photoGallery, ...action.payload?.data];
      } else {
        state.photoGallery = action.payload?.data;
      }
    });
    builder.addCase(getPhotoGallery.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(createPhotoGallery.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(createPhotoGallery.fulfilled, (state, action) => {
      state.loading = false;
      state.limitOffset = {
        ...state.limitOffset,
        photoGallery: { limit: 20, offset: 0 },
      };
    });
    builder.addCase(createPhotoGallery.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(deletePhotoGallery.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(deletePhotoGallery.fulfilled, (state, action) => {
      state.loading = false;
      state.photoGallery = state.photoGallery.filter(
        (el) => el.id !== action.payload?.id
      );
    });
    builder.addCase(deletePhotoGallery.rejected, (state, action) => {
      state.loading = false;
    });

    // EXHIBITION ==========
    builder.addCase(getExhibition.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getExhibition.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload) {
        state.exhibition = action.payload;
        state.currentExhibition = action.payload
          ?.filter((el) => new Date(el.start_date) > new Date())
          ?.reverse()[0];
      }
    });
    builder.addCase(getExhibition.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(createExhibition.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(createExhibition.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(createExhibition.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(putExhibition.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(putExhibition.fulfilled, (state, action) => {
      state.loading = false;
      state.exhibition = state.exhibition.map((el) => {
        if (el.id === action.payload?.id) {
          return action.payload?.body;
        }
        return el;
      });
    });
    builder.addCase(putExhibition.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(deleteExhibition.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(deleteExhibition.fulfilled, (state, action) => {
      state.loading = false;
      state.exhibition = state.exhibition.filter(
        (el) => el.id !== action.payload?.id
      );
    });
    builder.addCase(deleteExhibition.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(getExhibitionImage.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getExhibitionImage.fulfilled, (state, action) => {
      state.loading = false;
      state.exhibitionImage = action.payload;
    });
    builder.addCase(getExhibitionImage.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(getExhibitionVideo.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getExhibitionVideo.fulfilled, (state, action) => {
      state.loading = false;
      state.exhibitionVideo = action.payload;
    });
    builder.addCase(getExhibitionVideo.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(createExhibitionImage.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(createExhibitionImage.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(createExhibitionImage.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(createExhibitionVideo.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(createExhibitionVideo.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(createExhibitionVideo.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(deleteExhibitionFile.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(deleteExhibitionFile.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(deleteExhibitionFile.rejected, (state, action) => {
      state.loading = false;
    });

    // NEWS ==========
    builder.addCase(getNews.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getNews.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload?.offset > 0) {
        state.news = [...state.news, ...action.payload?.data];
      } else {
        state.news = action.payload?.data;
      }
    });
    builder.addCase(getNews.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(createNews.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(createNews.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(createNews.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(putNews.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(putNews.fulfilled, (state, action) => {
      state.loading = false;
      state.news = state.news.map((el) => {
        if (el.id === action.payload?.body?.id) {
          return action.payload?.body;
        }
        return el;
      });
    });
    builder.addCase(putNews.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(deleteNews.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(deleteNews.fulfilled, (state, action) => {
      state.loading = false;
      state.news = state.news.filter((el) => el.id !== action.payload?.id);
    });
    builder.addCase(deleteNews.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const {
  setLimitOffset,
  setFormState,
  setPreviewImage,
  setPreviewOpen,
  setPreviewVideo,
} = globalState.actions;
export default globalState.reducer;
